body {
  background-color: rgb(139, 205, 207);
}

.container {
  display: flex;
  justify-content: space-evenly; 
  margin-right: 1rem;
  margin-left: 1rem;
}

.column-1 {
  flex-shrink: 0;  /* shrinks to 0 to apply 70% width */
  flex-basis: 50%; /* sets initial width to 70% */
  padding: 0 0.5rem;
}

.leonardo-arduino-img {
  margin: 1rem 0 3rem;
}

@media only screen and (min-width: 600px) {
  .leonardo-arduino-img {
    margin-right: 2rem;
  }
  .midi-small {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .midi-large {
    display: none;
  }
  .container {
      /* it place the items in vertical direction */
    flex-direction: column;
  }

  .box {
    margin: 0 0 1rem;

  }

}

h2 {
  color: rgb(29,113,116);
  text-align: center;
}

main {
  margin-left: 2rem;
  margin-right: 2rem;  
}

.card-h3 {
  padding-top: 1rem;
  text-align: center;
}

.card-container {
  text-align: center;
}

.midi-message-card-container {
  margin: 0rem 1rem;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

ul {
  list-style-type: none;
}

ul li {
  margin-bottom: 0.5rem;
}
.card-a {
  text-decoration: none;
  font-weight: bold;
  color: rgb(32, 132, 136);
}

.card-a:hover,
.card-a:active,
.card-a.active {
  color: rgb(22, 101, 103);
}

.img-responsive {
  max-width: 100%;
  height: auto;
}

.circuit-img {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.component-list {
  padding-bottom: 1rem;
}