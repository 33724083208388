footer {
    min-height: 5vh;
    margin-top: auto;
    background-color: rgb(156, 252, 252);
    padding: 50px 0;
    width: 100%;
    color: rgb(74, 74, 74);
  }
  
  footer a {
    color: rgb(32, 132, 136);
    text-decoration: none;
    font-weight: bold;
  }
  
  footer a:hover,
  footer a:active,
  footer a.active {
    color: rgb(22, 101, 103);
  }
  