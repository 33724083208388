.header {
    width: 100%;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(32, 132, 136);
    padding: 0 10%;
  }
  
  .logo {
    font-size: 2rem;
    color: white;
    font-weight: bold;
  }

  .arduino-icon {
    height: 1.5rem;
    margin-right: 1rem;
  }
  
  .header ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: baseline;
    margin-left: -5rem;
  }
  
  .header li {
    margin-left: 2rem;
  }

  .header a {
    text-decoration: none;
    font-size: 1.5rem;
    color: rgb(156, 252, 252);
  }
  
  .header a:hover,
  .header a:active,
  .header a.active {
    color: white;
  }
  
  .badge {
    background-color: #cc2062;
    color: white;
    border-radius: 12px;
    padding: 0 1rem;
    margin-left: 0.5rem;
  }

  @media only screen and (max-width: 720px) {
    .logo {
      font-size: 1.5rem;
    }

    .header ul {
      margin-left: -2rem;
    }

    .header li {
      margin-left: 1rem;
    }
    
    .header a {
      font-size: 1.5rem;
    }

    .arduino-icon {
      height: 1.2rem;
      margin-bottom: -1px;
      margin-right: 0.3rem;
    }

    .brand {
      display: none;
    }

  } 
