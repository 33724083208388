
.video-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    /* padding-top: 56.25%;  */
    padding-top: 66%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  
  /* Then style the iframe to fit in the container div with full height and width */
  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    height: 100%;
  }